// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import styles from "./tailwind.css";
import { useEffect } from "react";
import { message } from "antd";
import { getSession } from "./session";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}];
export default function App() {
  _s();
  const {
    flashMessage
  } = useLoaderData();
  useEffect(() => {
    if (flashMessage) {
      flashMessage.status == "error" ? message.error(flashMessage.title, 2) : message.success(flashMessage.title, 2);
    }
  }, [flashMessage]);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s(App, "VdJS8E+URCNcgvjeu+aZRFd9wfk=", false, function () {
  return [useLoaderData];
});
_c = App;
export const loader = async ({
  request
}) => {
  const session = await getSession(request.headers.get("Cookie"));
  const flashMessage = session.get("message") || null;
  return {
    flashMessage
  };
};
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;